<template>
	<v-row>
		<!-- DIALOGS ADICIONAIS DA TABELA ACUMULADO -->
		<v-dialog v-model="dialogCredu" scrollable>
			<v-card class="primary">
				<v-card-title class="pl-4 pr-3 white--text">
					<span>Valores Acumulado Credu</span>
					<v-spacer />
				</v-card-title>
				<v-card-text class="pa-0">
					<v-simple-table class="pa-0 rounded-0" dense fixed-header height="70vh">
						<template v-slot:default>
							<thead class="header">
								<tr>
									<th class="text-center">Cód. Filial</th>
									<th>Filial</th>
									<th v-for="(m, i) in 4" :key="i">Mês {{ m }}/{{ busca.anoSelecionado }}</th>
									<th>Total Filial</th>
								</tr>
							</thead>
							<tbody class="body text-capitalize">
								<tr v-for="(d, i) in dadosAcumulado.lista" :key="i">
									<td class="text-center">{{ d.idempresa }}</td>
									<td>{{ d.empresa }}</td>
									<td>{{ d.mes01 | formataDinheiro }}</td>
									<td>{{ d.mes02 | formataDinheiro }}</td>
									<td>{{ d.mes03 | formataDinheiro }}</td>
									<td>{{ d.mes04 | formataDinheiro }}</td>
									<td>{{ d.totalfilial | formataDinheiro }}</td>
								</tr>
							</tbody>
							<tfoot class="foot text-capitalize" v-if="dadosAcumulado.lista != null">
								<tr class="font-weight-bold">
									<td colspan="2">Total:</td>
									<th>{{ dadosAcumulado.totalmes01 | formataDinheiro }}</th>
									<th>{{ dadosAcumulado.totalmes02 | formataDinheiro }}</th>
									<th>{{ dadosAcumulado.totalmes03 | formataDinheiro }}</th>
									<th>{{ dadosAcumulado.totalmes04 | formataDinheiro }}</th>
									<th>{{ dadosAcumulado.lista.map(v => v.totalfilial).reduce((a, v) => a + v, 0) |
										formataDinheiro }}</th>
								</tr>
							</tfoot>
						</template>

					</v-simple-table>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-3">
					<v-spacer />
					<v-btn :loading="carregando3" class="px-5" color="primary" elevation="0"
						@click="dialogCredu = false">Fechar</v-btn>
					<v-spacer />
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialog" scrollable>
			<v-card class="primary">
				<v-card-title class="pl-4 pr-3 white--text">
					<span>Valores Totais Recebidos Credu</span>
					<v-spacer />
				</v-card-title>
				<v-card-text class="pa-0">
					<v-simple-table class="pa-0 rounded-0" dense fixed-header height="70vh">
						<template v-slot:default>
							<thead class="header">
								<tr>
									<th class="text-center">Cód. Filial</th>
									<th>Filial</th>
									<th v-for="(m, i) in 12" :key="i">Mês {{ m }}/{{ busca.anoSelecionado }}</th>
									<th>Total Ano/{{ busca.anoSelecionado }}</th>
								</tr>
							</thead>
							<tbody class="body text-capitalize">
								<tr v-for="(d, index) in empresasAgrupadas" :key="index">
									<td class="text-center">{{ d.idempresa }}</td>
									<td>{{ d.empresa }}</td>
									<td v-for="mes in d.meses" :key="mes">{{ mes | formataDinheiro }}</td>
									<td>{{ d.total | formataDinheiro }}</td>
								</tr>
							</tbody>
							<tfoot class="foot text-capitalize" v-if="dadosRecebido.totalmes != null">
								<tr class="font-weight-bold">
									<td colspan="2">Total:</td>
									<td>{{ dadosRecebido.totalmes["01"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["02"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["03"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["04"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["05"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["06"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["07"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["08"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["09"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["10"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["11"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["12"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.total | formataDinheiro }}</td>
								</tr>
							</tfoot>
						</template>
					</v-simple-table>


				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-3">
					<v-spacer />
					<v-btn :loading="carregando" class="px-5" color="primary" elevation="0"
						@click="dialog = false">Fechar</v-btn>
					<v-spacer />
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- FILTRO DE DATA -->
		<v-col cols="12">
			<v-card>
				<v-card-text>
					<v-row no-gutters class="mb-n6">
						<v-col class="pr-2">
							<v-select :items="datas" item-text="name" item-value="value" label="Período" outlined dense
								v-model="busca.data"></v-select>
						</v-col>
						<v-col cols="auto" class="pl-2">
							<v-btn :disabled="carregando" class="mt-1" color="primary" elevation="0" fab rounded x-small
								@click="listar()">
								<v-icon>mdi-magnify</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-col>

		<v-col cols="12">

			<v-card class="primary">
				<v-card-title class="white--text">
					Resumo Credu {{ busca.anoSelecionado }}
				</v-card-title>
				<v-divider class="white" />
				<v-card-text class="pa-2">
					<v-expansion-panels v-model="panels" class="custom-padding ">

						<!-- PAINEL 1 - PAINEL DE CREDU -->
						<v-expansion-panel active-class="primary white--text rounded-0">

							<v-expansion-panel-header v-model="headerOpen" class="py-4" hide-actions v-slot="{ open }">
								<v-row align="center" no-gutters>
									<v-col cols="4" class="text-start text-h6">Credu Março/{{ busca.anoSelecionado
										}}</v-col>
									<v-col cols="8">
										<v-row v-if="!open">
											<v-col class="text-center font-weight-bold">Total Credu <br /> <v-chip small
													color="primary" class="mt-1">{{ dados.firstmonth.map(v =>
														v.total).reduce((a, v) => a + v, 0) | formataDinheiro
													}}</v-chip></v-col>
											<v-col class="text-center font-weight-bold">Venda Últimos 3 Meses <br />
												<v-chip small color="primary" class="mt-1">{{ dados.firstmonth.map(v =>
													v.venda).reduce((a, v) => a + v, 0) | formataDinheiro
													}}</v-chip></v-col>
											<v-col class="text-center font-weight-bold">Índice <br /> <v-chip small
													color="primary" class="mt-1">{{ ((dados.firstmonth.map(v =>
														v.total).reduce((a, v) => a + v, 0))
														/
														(dados.firstmonth.map(v => v.venda).reduce((a, v) => a + v, 0) || 1)
														*
														100).toFixed(2) }}%</v-chip></v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
								<v-simple-table v-else dense fixed-header height="70vh" class="elevation-0">
									<template v-slot:default>
										<thead>
											<tr>
												<th>Rank</th>
												<th>Cód. Filial</th>
												<th>Filial</th>
												<th>Crediário</th>
												<th>Cheques</th>
												<th>Promissórias</th>
												<th>Total Credu</th>
												<th>Venda 3 Últ. Meses</th>
												<th>Índice</th>
											</tr>
										</thead>
										<tbody class="body text-capitalize" v-if="dados != null">
											<tr v-for="(d, index) in dados.firstmonth" :key="index">
												<td
													:style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : ''">
													{{ d.rk }}</td>
												<td
													:style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : ''">
													{{ d.idempresa }}</td>
												<td
													:style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : ''">
													{{ d.empresa }}</td>
												<td
													:style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : ''">
													{{ d.crediario | formataDinheiro }}</td>
												<td
													:style="d.indice >= 0.5 && d.cheque >= 0 ? 'color:#F22233; font-weight: bold' : ''">
													{{
														d.cheque | formataDinheiro }}</td>
												<td
													:style="d.indice >= 0.5 && d.promissoria >= 0 ? 'color:#F22233; font-weight: bold' : ''">
													{{ d.promissoria | formataDinheiro }}</td>
												<td :style="d.indice >= 0.5 ? 'color:#F22233; font-weight: bold' : ''">
													{{
														d.total |
														formataDinheiro }}</td>
												<td
													:style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : ''">
													{{ d.venda | formataDinheiro }}</td>
												<td :style="d.indice >= 0.5 ? 'color:#F22233; font-weight: bold;' : ''">
													{{
														(d.indice).toFixed(2) }}%</td>
											</tr>
										</tbody>
										<tfoot class="foot text-capitalize" v-if="dados != null && !carregando">
											<tr class="cursor-normal">
												<td nowrap></td>
												<td nowrap></td>
												<td nowrap class="bold-text">Totais:</td>
												<td nowrap class="bold-text">{{ dados.firstmonth.map(v =>
													v.crediario).reduce((a, v) => a + v, 0) |
													formataDinheiro }}</td>
												<td nowrap class="bold-text">{{ dados.firstmonth.map(v =>
													v.cheque).reduce((a, v) => a + v, 0) |
													formataDinheiro }}</td>
												<td nowrap class="bold-text">{{ dados.firstmonth.map(v =>
													v.promissoria).reduce((a, v) => a + v, 0) |
													formataDinheiro }}</td>
												<td nowrap class="bold-text">{{ dados.firstmonth.map(v =>
													v.total).reduce((a, v) => a + v, 0) |
													formataDinheiro }}</td>
												<td nowrap class="bold-text">{{ dados.firstmonth.map(v =>
													v.venda).reduce((a, v) => a + v, 0) |
													formataDinheiro }}</td>
												<td nowrap class="bold-text">
													{{ ((dados.firstmonth.map(v => v.total).reduce((a, v) => a + v, 0))
														/
														(dados.firstmonth.map(v => v.venda).reduce((a, v) => a + v, 0) || 1)
														*
														100).toFixed(2) }}%
												</td>
											</tr>
										</tfoot>
										<tbody v-else>
											<tr>
												<td class="text-center" colspan="8">Nenhum registro encontrado.</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-expansion-panel-content>
						</v-expansion-panel>

						<!-- PAINEL 2 - PAINEL DE CREDU -->
						<v-expansion-panel active-class="primary white--text rounded-0">
							<v-expansion-panel-header v-model="headerOpen" class="py-4" hide-actions v-slot="{ open }">
								<v-row align="center" no-gutters>
									<v-col cols="4" class="text-start text-h6">Credu Junho/{{ busca.anoSelecionado
										}}</v-col>
									<v-col cols="8">
										<v-row v-if="!open">
											<v-col class="text-center font-weight-bold">Total Credu <br /> <v-chip small
													color="primary" class="mt-1">{{ dados.secondmonth.map(v =>
														v.total).reduce((a, v) => a + v, 0) | formataDinheiro
													}}</v-chip></v-col>
											<v-col class="text-center font-weight-bold">Venda Últimos 3 Meses <br />
												<v-chip small color="primary" class="mt-1">{{ dados.secondmonth.map(v =>
													v.venda).reduce((a, v) => a + v, 0) | formataDinheiro
													}}</v-chip></v-col>
											<v-col class="text-center font-weight-bold">Índice <br /> <v-chip small
													color="primary" class="mt-1">{{ ((dados.secondmonth.map(v =>
														v.total).reduce((a, v) => a + v, 0))
														/
														(dados.secondmonth.map(v => v.venda).reduce((a, v) => a + v, 0) ||
															1)
														*
														100).toFixed(2) }}%</v-chip></v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
								<v-simple-table v-else dense fixed-header height="70vh">
									<template v-slot:default>


										<thead>
											<tr>
												<th>Rank</th>
												<th>Cód. Filial</th>
												<th>Filial</th>
												<th>Crediário</th>
												<th>Cheques</th>
												<th>Promissórias</th>
												<th>Total Credu</th>
												<th>Venda 3 Últ. Meses</th>
												<th>Índice</th>
											</tr>
										</thead>
										<tbody class="body text-capitalize" v-if="dados != null">
											<tr v-for="(d, index) in dados.secondmonth" :key="index">
												<td
													:style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : ''">
													{{ d.rk }}</td>
												<td
													:style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : ''">
													{{ d.idempresa }}</td>
												<td
													:style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : ''">
													{{ d.empresa }}</td>
												<td
													:style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : ''">
													{{ d.crediario | formataDinheiro }}</td>
												<td
													:style="d.indice >= 0.5 && d.cheque >= 0 ? 'color:#F22233; font-weight: bold' : ''">
													{{
														d.cheque | formataDinheiro }}</td>
												<td
													:style="d.indice >= 0.5 && d.promissoria >= 0 ? 'color:#F22233; font-weight: bold' : ''">
													{{ d.promissoria | formataDinheiro }}</td>
												<td :style="d.indice >= 0.5 ? 'color:#F22233; font-weight: bold' : ''">
													{{
														d.total |
														formataDinheiro }}</td>
												<td>{{ d.venda | formataDinheiro }}</td>
												<td :style="d.indice >= 0.5 ? 'color:#F22233; font-weight: bold;' : ''">
													{{
														(d.indice).toFixed(2) }}%</td>
											</tr>
										</tbody>
										<tfoot class="foot text-capitalize" v-if="dados != null && !carregando">
											<tr class="cursor-normal">
												<td nowrap></td>
												<td nowrap></td>
												<td nowrap class="bold-text">Totais:</td>
												<td nowrap class="bold-text">{{ dados.secondmonth.map(v =>
													v.crediario).reduce((a, v) => a + v, 0) |
													formataDinheiro }}</td>
												<td nowrap class="bold-text">{{ dados.secondmonth.map(v =>
													v.cheque).reduce((a, v) => a + v, 0) |
													formataDinheiro }}</td>
												<td nowrap class="bold-text">{{ dados.secondmonth.map(v =>
													v.promissoria).reduce((a, v) => a + v, 0) |
													formataDinheiro }}</td>
												<td nowrap class="bold-text">{{ dados.secondmonth.map(v =>
													v.total).reduce((a, v) => a + v, 0) |
													formataDinheiro }}</td>
												<td nowrap class="bold-text">{{ dados.secondmonth.map(v =>
													v.venda).reduce((a, v) => a + v, 0) |
													formataDinheiro }}</td>
												<td nowrap class="bold-text">
													{{ ((dados.secondmonth.map(v => v.total).reduce((a, v) => a + v, 0))
														/
														(dados.secondmonth.map(v => v.venda).reduce((a, v) => a + v, 0) ||
															1) *
														100).toFixed(2) }}%
												</td>
											</tr>
										</tfoot>
										<tbody v-else>
											<tr>
												<td class="text-center" colspan="8">Nenhum registro encontrado.</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-expansion-panel-content>
						</v-expansion-panel>

						<!-- PAINEL 3 - PAINEL DE CREDU -->
						<v-expansion-panel active-class="primary white--text rounded-0">
							<v-expansion-panel-header v-model="headerOpen" class="py-4" hide-actions v-slot="{ open }">
								<v-row align="center" no-gutters>
									<v-col cols="4" class="text-start text-h6">Credu Setembro/{{ busca.anoSelecionado
										}}</v-col>
									<v-col cols="8">
										<v-row v-if="!open">
											<v-col class="text-center font-weight-bold">Total Credu <br /> <v-chip small
													color="primary" class="mt-1">{{ dados.thirdmonth.map(v =>
														v.total).reduce((a, v) => a + v, 0) | formataDinheiro
													}}</v-chip></v-col>
											<v-col class="text-center font-weight-bold">Venda Últimos 3 Meses <br />
												<v-chip small color="primary" class="mt-1">{{ dados.thirdmonth.map(v =>
													v.venda).reduce((a, v) => a + v, 0) | formataDinheiro
													}}</v-chip></v-col>
											<v-col class="text-center font-weight-bold">Índice <br /> <v-chip small
													color="primary" class="mt-1">{{ ((dados.thirdmonth.map(v =>
														v.total).reduce((a, v) => a + v, 0))
														/
														(dados.thirdmonth.map(v => v.venda).reduce((a, v) => a + v, 0) || 1)
														*
														100).toFixed(2) }}%</v-chip></v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
								<v-simple-table v-else dense fixed-header height="70vh">
									<template v-slot:default>
										<thead>
											<tr>
												<th>Rank</th>
												<th>Cód. Filial</th>
												<th>Filial</th>
												<th>Crediário</th>
												<th>Cheques</th>
												<th>Promissórias</th>
												<th>Total Credu</th>
												<th>Venda 3 Últ. Meses</th>
												<th>Índice</th>
											</tr>
										</thead>
										<tbody class="body text-capitalize" v-if="dados != null">
											<tr v-for="(d, index) in dados.thirdmonth" :key="index">
												<td
													:style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : ''">
													{{ d.rk }}</td>
												<td
													:style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : ''">
													{{ d.idempresa }}</td>
												<td
													:style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : ''">
													{{ d.empresa }}</td>
												<td
													:style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : ''">
													{{ d.crediario | formataDinheiro }}</td>
												<td
													:style="d.indice >= 0.5 && d.cheque >= 0 ? 'color:#F22233; font-weight: bold' : ''">
													{{
														d.cheque | formataDinheiro }}</td>
												<td
													:style="d.indice >= 0.5 && d.promissoria >= 0 ? 'color:#F22233; font-weight: bold' : ''">
													{{ d.promissoria | formataDinheiro }}</td>
												<td :style="d.indice >= 0.5 ? 'color:#F22233; font-weight: bold' : ''">
													{{
														d.total |
														formataDinheiro }}</td>
												<td>{{ d.venda | formataDinheiro }}</td>
												<td :style="d.indice >= 0.5 ? 'color:#F22233; font-weight: bold;' : ''">
													{{
														(d.indice).toFixed(2) }}%</td>
											</tr>
										</tbody>
										<tfoot class="foot text-capitalize" v-if="dados != null && !carregando">
											<tr class="cursor-normal">
												<td nowrap></td>
												<td nowrap></td>
												<td nowrap class="bold-text">Totais:</td>
												<td nowrap class="bold-text">{{ dados.thirdmonth.map(v =>
													v.crediario).reduce((a, v) => a + v, 0) |
													formataDinheiro }}</td>
												<td nowrap class="bold-text">{{ dados.thirdmonth.map(v =>
													v.cheque).reduce((a, v) => a + v, 0) |
													formataDinheiro }}</td>
												<td nowrap class="bold-text">{{ dados.thirdmonth.map(v =>
													v.promissoria).reduce((a, v) => a + v, 0) |
													formataDinheiro }}</td>
												<td nowrap class="bold-text">{{ dados.thirdmonth.map(v =>
													v.total).reduce((a, v) => a + v, 0) |
													formataDinheiro }}</td>
												<td nowrap class="bold-text">{{ dados.thirdmonth.map(v =>
													v.venda).reduce((a, v) => a + v, 0) |
													formataDinheiro }}</td>
												<td nowrap class="bold-text">
													{{ ((dados.thirdmonth.map(v => v.total).reduce((a, v) => a + v, 0))
														/
														(dados.thirdmonth.map(v => v.venda).reduce((a, v) => a + v, 0) || 1)
														*
														100).toFixed(2) }}%
												</td>
											</tr>
										</tfoot>
										<tbody v-else>
											<tr>
												<td class="text-center" colspan="8">Nenhum registro encontrado.</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-expansion-panel-content>
						</v-expansion-panel>

						<!-- PAINEL 4 - PAINEL DE CREDU -->
						<v-expansion-panel active-class="primary white--text rounded-0">
							<v-expansion-panel-header v-model="headerOpen" class="py-4" hide-actions v-slot="{ open }">
								<v-row align="center" no-gutters>
									<v-col cols="4" class="text-start text-h6">Credu Dezembro/{{ busca.anoSelecionado
										}}</v-col>
									<v-col cols="8">
										<v-row v-if="!open">
											<v-col class="text-center font-weight-bold">Total Credu <br /> <v-chip small
													color="primary" class="mt-1">{{ dados.fourthmonth.map(v =>
														v.total).reduce((a, v) => a + v, 0) | formataDinheiro
													}}</v-chip></v-col>
											<v-col class="text-center font-weight-bold">Venda Últimos 3 Meses <br />
												<v-chip small color="primary" class="mt-1">{{ dados.fourthmonth.map(v =>
													v.venda).reduce((a, v) => a + v, 0) | formataDinheiro
													}}</v-chip></v-col>
											<v-col class="text-center font-weight-bold">Índice <br /> <v-chip small
													color="primary" class="mt-1">{{ ((dados.fourthmonth.map(v =>
														v.total).reduce((a, v) => a + v, 0))
														/
														(dados.fourthmonth.map(v => v.venda).reduce((a, v) => a + v, 0) ||
															1)
														*
														100).toFixed(2) }}%</v-chip></v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
								<v-simple-table v-else dense fixed-header height="70vh">
									<template v-slot:default>
										<thead>
											<tr>
												<th>Rank</th>
												<th>Cód. Filial</th>
												<th>Filial</th>
												<th>Crediário</th>
												<th>Cheques</th>
												<th>Promissórias</th>
												<th>Total Credu</th>
												<th>Venda 3 Últ. Meses</th>
												<th>Índice</th>
											</tr>
										</thead>
										<tbody class="body text-capitalize" v-if="dados != null">
											<tr v-for="(d, index) in dados.fourthmonth" :key="index">
												<td
													:style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : ''">
													{{ d.rk }}</td>
												<td
													:style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : ''">
													{{ d.idempresa }}</td>
												<td
													:style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : ''">
													{{ d.empresa }}</td>
												<td
													:style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : ''">
													{{ d.crediario | formataDinheiro }}</td>
												<td
													:style="d.indice >= 0.5 && d.cheque >= 0 ? 'color:#F22233; font-weight: bold' : ''">
													{{
														d.cheque | formataDinheiro }}</td>
												<td
													:style="d.indice >= 0.5 && d.promissoria >= 0 ? 'color:#F22233; font-weight: bold' : ''">
													{{ d.promissoria | formataDinheiro }}</td>
												<td :style="d.indice >= 0.5 ? 'color:#F22233; font-weight: bold' : ''">
													{{
														d.total |
														formataDinheiro }}</td>
												<td>{{ d.venda | formataDinheiro }}</td>
												<td :style="d.indice >= 0.5 ? 'color:#F22233; font-weight: bold;' : ''">
													{{
														(d.indice).toFixed(2) }}%</td>
											</tr>
										</tbody>
										<tfoot class="foot text-capitalize" v-if="dados != null && !carregando">
											<tr class="cursor-normal">
												<td nowrap></td>
												<td nowrap></td>
												<td nowrap class="bold-text">Totais:</td>
												<td nowrap class="bold-text">{{ dados.fourthmonth.map(v =>
													v.crediario).reduce((a, v) => a + v, 0) |
													formataDinheiro }}</td>
												<td nowrap class="bold-text">{{ dados.fourthmonth.map(v =>
													v.cheque).reduce((a, v) => a + v, 0) |
													formataDinheiro }}</td>
												<td nowrap class="bold-text">{{ dados.fourthmonth.map(v =>
													v.promissoria).reduce((a, v) => a + v, 0) |
													formataDinheiro }}</td>
												<td nowrap class="bold-text">{{ dados.fourthmonth.map(v =>
													v.total).reduce((a, v) => a + v, 0) |
													formataDinheiro }}</td>
												<td nowrap class="bold-text">{{ dados.fourthmonth.map(v =>
													v.venda).reduce((a, v) => a + v, 0) |
													formataDinheiro }}</td>
												<td nowrap class="bold-text">
													{{ ((dados.fourthmonth.map(v => v.total).reduce((a, v) => a + v, 0))
														/
														(dados.fourthmonth.map(v => v.venda).reduce((a, v) => a + v, 0) ||
															1) *
														100).toFixed(2) }}%
												</td>
											</tr>
										</tfoot>
										<tbody v-else>
											<tr>
												<td class="text-center" colspan="8">Nenhum registro encontrado.</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-expansion-panel-content>
						</v-expansion-panel>


					</v-expansion-panels>
				</v-card-text>
			</v-card>

			<v-card class="mt-4 primary">
				<v-card-title class="text-h6 white--text">
					Acumulado {{ busca.anoSelecionado }}
				</v-card-title>
				<v-card-text class="pa-2">

					<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
					<v-simple-table v-else dense fixed-header height="70vh" width="80vh">
						<template v-slot:default>
							<thead>
								<tr>
									<th>Rank</th>
									<th>Cód. Filial</th>
									<th class="pr-0">Filial</th>
									<th class="pr-0">
										Credu Acumulado
										<v-icon @click="dialogCredu = true" color="primary" class="ml-2"
											size="20">mdi-magnify-plus-outline</v-icon>
									</th>
									<th class="pr-0">Índice s/ Venda</th>
									<th class="pr-0">Venda Acumulada</th>
									<th class="pr-0">Meta</th>
									<th class="pr-0">
										Total Recebido Acumulado
										<v-icon @click="dialog = true" color="primary" class="ml-2"
											size="20">mdi-magnify-plus-outline</v-icon>
									</th>
									<th class="pr-0">Índice de Recebimento</th>
									<th class="pr-0">Saldo a Receber</th>
									<th class="pr-0">Índice Atual</th>
								</tr>
							</thead>
							<tbody class="body text-capitalize" v-if="dados.creduacumulado != null">
								<tr v-for="(d, i) in dados.creduacumulado" :key="i">
									<td>{{ d.rk }}</td>
									<td>{{ d.idempresa }}</td>
									<td class="pr-0" nowrap>{{ d.empresa | formataTextoPedido }}</td>
									<td class="pr-0">{{ d.creduacumulado | formataDinheiro }}</td>
									<td class="pr-0">{{ (d.indicesobrevenda).toFixed(2) }}%</td>
									<td class="pr-0">{{ d.vendaacumulada | formataDinheiro }}</td>
									<td class="pr-0">{{ d.meta | formataDinheiro }}</td>
									<td class="pr-0">{{ d.credurecebido | formataDinheiro }}</td>
									<td class="pr-0">{{ (d.indicerecebimento).toFixed(2) }}%</td>
									<td class="pr-0">{{ d.saldoreceber | formataDinheiro }}</td>
									<td class="pr-0">{{ (d.indiceatual).toFixed(2) }}%</td>
								</tr>
							</tbody>
							<tfoot class="foot text-capitalize" v-if="dados != null && !carregando">
								<tr class="cursor-normal">
									<td nowrap></td>
									<td nowrap></td>
									<td nowrap class="bold-text">Totais:</td>
									<td nowrap class="bold-text">{{ dados.creduacumulado.map(v =>
										v.creduacumulado).reduce((a, v) => a + v, 0) |
										formataDinheiro }}</td>
									<td nowrap class="bold-text">
										{{ ((dados.creduacumulado.map(v => v.creduacumulado).reduce((a, v) => a
											+ v,
											0))
											/
											(dados.creduacumulado.map(v => v.vendaacumulada).reduce((a, v) => a + v,
												0)
												||
												1) *
											100).toFixed(2) }}%
									</td>
									<td nowrap class="bold-text">{{ dados.creduacumulado.map(v =>
										v.vendaacumulada).reduce((a, v) => a + v, 0) |
										formataDinheiro }}</td>
									<td nowrap class="bold-text">{{ dados.creduacumulado.map(v =>
										v.meta).reduce((a, v) => a + v, 0) |
										formataDinheiro }}</td>
									<td nowrap class="bold-text">{{ dados.creduacumulado.map(v =>
										v.credurecebido).reduce((a, v) => a + v, 0) |
										formataDinheiro }}</td>
									<td nowrap class="bold-text">
										{{ ((dados.creduacumulado.map(v => v.credurecebido).reduce((a, v) => a +
											v,
											0))
											/
											(dados.creduacumulado.map(v => v.creduacumulado).reduce((a, v) => a + v,
												0)
												||
												1) *
											100).toFixed(2) }}%
									</td>
									<td nowrap class="bold-text">{{ dados.creduacumulado.map(v =>
										v.saldoreceber).reduce((a, v) => a + v, 0) |
										formataDinheiro }}</td>
									<td nowrap class="bold-text">
										{{ ((dados.creduacumulado.map(v => v.saldoreceber).reduce((a, v) => a +
											v,
											0)) /
											(dados.creduacumulado.map(v => v.vendaacumulada).reduce((a, v) => a + v,
												0)
												||
												1) *
											100).toFixed(2) }}%
									</td>
								</tr>
							</tfoot>
							<tbody v-else>
								<tr>
									<td class="text-center" colspan="10">Nenhum registro encontrado.</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
			</v-card>

		</v-col>
	</v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
	name: "CreduLancamento",
	data: () => ({
		dialog: false,
		dialogCredu: false,
		corAtencao: "",
		anoAtual: new Date(),
		busca: { data: "", anoSelecionado: "" },
		datas: [{ name: "", value: "" }],
		carregando: true,
		carregando2: false,
		carregando3: false,
		carregandoSkeleton: true,
		dados: {
			creduacumulado: [],
			firstmonth: [],
			secondmonth: [],
			thirdmonth: [],
			fourthmonth: [],
		},
		dadosAcumulado: {
			lista: [],
			totalmes01: 0,
			totalmes02: 0,
			totalmes03: 0,
			totalmes04: 0
		},
		dadosRecebido: {
			lista: [],
			total: 0,
			totalfiliais: {},
			totalmes: {},
		},
		totaisMeses: [],
		totalGeral: 0,
		panels: [4],
		panelacumulado: [1],
		headerOpen: false,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		empresasAgrupadas() {
			// Agrupar os dados por empresa e organizar por mês
			const empresas = {};

			this.dadosRecebido.lista.forEach(item => {
				if (!empresas[item.empresa]) {
					empresas[item.empresa] = {
						idempresa: item.idempresa,
						empresa: item.empresa,
						meses: new Array(12).fill(0), // Array para armazenar os meses
						total: 0 // Inicializa o total
					};
				}
				empresas[item.empresa].meses[item.mes - 1] = item.totalrecebido; // Preenche o mês correto
				empresas[item.empresa].total += item.totalrecebido; // Incrementa o total
			});

			// Converte o objeto para um array de empresas
			return Object.values(empresas);
		},
	},
	methods: {
		listar() {
			this.carregando = true;
			this.dados.firstmonth = [];
			this.dados.secondmonth = [];
			this.dados.thirdmonth = [];
			this.dados.fourthmonth = [];
			this.dados.creduacumulado = [];
			return axios
				.post(`${this.backendUrl}credu/dashboard/listar`, {
					data: this.busca.data,
				})
				.then((res) => {
					this.busca.anoSelecionado = this.busca.data;
					this.dados.firstmonth = res.data.firstmonth;
					this.dados.secondmonth = res.data.secondmonth;
					this.dados.thirdmonth = res.data.thirdmonth;
					this.dados.fourthmonth = res.data.fourthmonth;
					this.dados.creduacumulado = res.data.creduacumulado;
					this.carregando = false;
					this.carregandoSkeleton = false;
				})
				.catch(() => {
					this.dados.firstmonth = [];
					this.dados.secondmonth = [];
					this.dados.thirdmonth = [];
					this.dados.fourthmonth = [];
					this.carregando = false;
					this.carregandoSkeleton = false;
				});
		},
		listarAcumulado() {
			this.carregando3 = true;
			this.dadosAcumulado.lista = [];
			this.dadosAcumulado.totalmes01 = 0;
			this.dadosAcumulado.totalmes02 = 0;
			this.dadosAcumulado.totalmes03 = 0;
			this.dadosAcumulado.totalmes04 = 0;
			return axios
				.post(`${this.backendUrl}credu/acumulado/listar`, {
					data: this.busca.data,
				})
				.then((res) => {
					this.busca.anoSelecionado = this.busca.data;
					this.dadosAcumulado.lista = res.data.lista;
					this.dadosAcumulado.totalmes01 = res.data.totalmes01;
					this.dadosAcumulado.totalmes02 = res.data.totalmes02;
					this.dadosAcumulado.totalmes03 = res.data.totalmes03;
					this.dadosAcumulado.totalmes04 = res.data.totalmes04;
					this.carregando3 = false;
					this.carregandoSkeleton = false;
				})
				.catch(() => {
					this.dadosAcumulado.lista = [];
					this.dadosAcumulado.totalmes01 = 0;
					this.dadosAcumulado.totalmes02 = 0;
					this.dadosAcumulado.totalmes03 = 0;
					this.dadosAcumulado.totalmes04 = 0;
					this.carregando3 = false;
					this.carregandoSkeleton = false;
				});
		},
		listarRecebido() {
			this.carregando3 = true;
			this.dadosRecebido.lista = [];
			this.dadosRecebido.total = 0;
			this.dadosRecebido.totalfiliais = {};
			this.dadosRecebido.totalmes = {};
			return axios
				.post(`${this.backendUrl}credu/recebido/listar`, {
					data: this.busca.data,
				})
				.then((res) => {
					this.busca.anoSelecionado = this.busca.data;
					this.dadosRecebido.lista = res.data.lista;
					this.dadosRecebido.total = res.data.total;
					this.dadosRecebido.totalfiliais = res.data.totalfiliais;
					this.dadosRecebido.totalmes = res.data.totalmes;
					this.carregando3 = false;
					this.carregandoSkeleton = false;
				})
				.catch(() => {
					this.dadosRecebido.lista = [];
					this.dadosRecebido.total = 0;
					this.dadosRecebido.totalfiliais = {};
					this.dadosRecebido.totalmes = {};
					this.carregando3 = false;
					this.carregandoSkeleton = false;
				});
		},
		periodos() {
			return axios
				.post(`${this.backendUrl}credu/data/listar`, {})
				.then((res) => {
					this.datas = res.data.lista.map((v) => {
						return {
							name: v.anoinicio.toString(),
							value: v.anoinicio.toString(),
						};
					});
				});
		},
		async init() {
			this.busca.idfilial = this.usuario.idfilial;
			this.busca.data = this.anoAtual.getFullYear().toString();
			this.busca.anoSelecionado = this.anoAtual.getFullYear();
			await this.periodos();
			await this.listar();
			await this.listarAcumulado();
			await this.listarRecebido();
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
.table {
	margin: 0px 0px 10px 0px;
}

.header th {
	background-color: #CFD8DC !important;
	height: 25px !important;
}

.body td {
	height: 20px !important;
	font-size: larger;
}

.bold-text {
	font-weight: bold;
}

.title-expanded {
	font-size: 18px
}

::v-deep .custom-padding .v-expansion-panel-content__wrap {
	padding: 0 !important;
}
</style>